import { videojs } from 'global/window';
import log from '../../../log';
/* eslint-disable */
import SubtitlesHeading from './subtitles-heading';
import SubtitlesButtonsWrapper from './subtitles-buttons-wrapper';
import SubtitlesButton from './subtitles-button';
/* eslint-enable */
import './subtitles-select/subtitles-select-wrapper';
import propTest from '../../../property-tester';

// Get a component to subclass
const Component = videojs.getComponent('Component');

/**
 * Text tracks (subtitles) - plugin initialization
 *
 * @param {Object} player - Player object
 * @param {Object=} options - Optional options
 * @extends Component
 */
class SubtitlesControl extends Component {
  constructor(player, options = {}) {
    super(player, options);
    this.player = player;
    options.parent = this;
    this.subtitlesControlOpts = propTest(() => this.player.options_.responsiveControl.subtitles);
    this.lastActiveSubtitles = null;

    if (this.subtitlesControlOpts) {
      Object.entries(this.subtitlesControlOpts).forEach((entry) => {
        // send approprate class to element
        let visibility;
        for (let i = 0; i < entry[1].length; i++) {
          if (entry[0] === 'inSettings') visibility = 'visible';
          if (entry[0] === 'alone') visibility = 'hidden';
          this.addClass(`vjs-bp-${entry[1][i]}-${visibility}`);
        }
      });
    }

    // default render in settings
    this.addChild('SubtitlesHeading');
    if (this.options_.dropdownSettings) {
      this.addChild('SubtitlesSelectWrapper');
    } else {
      this.addChild('SubtitlesButtonsWrapper');
    }

    // when configuration includes special rules, render also in controlBar
    if (this.subtitlesControlOpts) {
      this.player.controlBar.addChild('SubtitlesButton');
    }

    this.enableSubtitlesAfterMidroll();
  }

  /**
   * Create the component's DOM element
   *
   * @return {Element}
   */
  createEl() {
    return super.createEl('div', {
      className: 'vjs-subtitles-control vjs-settings-item',
    });
  }

  // enable last active subtitles after midroll ended
  // it should be done automatically, but it's not (we have to force it)
  // maybe some "videojs-contrib-ads" issue
  // more info: https://jira.zentity.com/browse/CRASUPP-915
  enableSubtitlesAfterMidroll() {
    const tracks = videojs.browser.IS_IOS
      ? Object.values(this.player.el().children[0].textTracks)
      : this.player_.remoteTextTracks().tracks_;

    this.player.on('ott-mea-ads-block-start', () => {
      // save enabled subtitle track before ad
      this.lastActiveSubtitles = tracks.find((track) => track.mode === 'showing');
    });

    this.player.on('ott-mea-ads-block-end', (e) => {
      if (e.adStreamInfo.adType !== 'midrolls') {
        return;
      }
      if (!this.lastActiveSubtitles) {
        return;
      }

      // call on "ott-mea-play" event as iOS fix
      this.player.one('ott-mea-play', () => {
        tracks.forEach((track) => {
          // use last saved subtitles to enabled subtitles after midroll ended
          if (track.language === this.lastActiveSubtitles.language) {
            track.mode = 'showing';
            this.player.trigger({
              type: 'ott-subtitles-enabled',
              track,
            });
            log(`[subtitles] enable subtitles after midroll ended (language: ${track.language})`);
          } else {
            track.mode = 'hidden';
          }
        });
      });
    });
  }
}

Component.registerComponent('SubtitlesControl', SubtitlesControl);
export default SubtitlesControl;
