import window from 'global/window';
import log from '../../log';
import { AS_AD_BUTTON_NOT_FOUND } from '../../errors';
import { showSkipDelay, showPremiumPromo } from './shared/settings-helper';
import getInventory from './shared/get-inventory';
import avlAdType from './shared/ad-type';
import Cascade from './cascade';
import DomOperations from './shared/dom-operations';
import { LinearTracker } from './shared/vast-tracker';
import { isVPAID } from './vpaid/utils';
import VPAID from './vpaid';
import PlayToggleFake from './vpaid/play-toggle-fake';
import sourceFormatter from '../../source-formatter';
import AdsPlayer from './adsPlayer';
import AdProxy from './adProxy';
import SimidManager from './simid/simid-manager';
import { hasSimid } from './simid/utils';
import { CsaiEvents } from '../adservice-csai/csai-events';
import availableEvents from '../measuring/shared/available-events';

/**
 * Cares about all linear process
 *
 * @param {Object} adstate                                      Advertisement state container object
 * @param {Object} player                                       Video.js player instance
 * @param {module:plugins/adService~AdSettingsObjj} settings    Ad service settigns
 */
class AdsLinear {
  constructor(adstate, player, settings) {
    this.adstate = adstate;
    this.player = player;
    this.settings = settings;
    this.domOperations = new DomOperations(player, settings);
    this.adsPlayer = new AdsPlayer(this.player, settings);

    if (settings.linear.adProxy && settings.linear.adProxy.proxyUrl) {
      this.adProxy = new AdProxy(settings.linear.adProxy);
    }

    this.player.on('ready', () => {
      this.registerFakePlayToggleButton();
      this.disableFullscreenInIOS();
      this.showVolumeControlInIOS();
    });
  }

  registerFakePlayToggleButton() {
    this.player.controlBar.seekForward = this.player.controlBar.addChild(new PlayToggleFake(this.player, {}));
  }

  disableFullscreenInIOS() {
    if (window.videojs.browser.IS_IOS) {
      // fullscreen workaround for iOS device, skip fullscreen
      this.player.on('adstart', () => {
        // "loadedmetadata" event fixes the bug when iOS player exits fullscreen and freezes the player with linear ad
        // * before on exitFullscreen() also triggers a pause (don't know why)
        // * but now no pause occurs, which looks like expected behaviour (same as IMA ads)
        // more info: https://jira.zentity.com/browse/CRASUPP-649
        this.player.one('loadedmetadata', () => {
          if (this.player.isFullscreen()) {
            this.player.exitFullscreen();
          }
        });
      });

      this.player.addClass('vjs-ios');
    }
  }

  showVolumeControlInIOS() {
    if (window.videojs.browser.IS_IOS) {
      this.player.one('loadstart', () => {
        this.player.controlBar.volumePanel.show();
        this.player.controlBar.volumePanel.muteToggle.show();
      });
    }
  }

  /**
   * Returns initial ad object
   *
   * @param {Object=} ad - Hash of options for the x-roll
   */
  getEmptyObject(ad) {
    return {
      played: 0,
      requested: false,
      vasts: (ad || {}).vasts || [],
      ads: [],
    };
  }

  /**
   * Returns extended getEmptyObject. Use this for midrolls (they are always arrays)
   *
   * @param {mixed} ad - Hash of options for the x-roll
   */
  getMidrollObject(ad) {
    const linearAd = this.getEmptyObject(ad);
    linearAd.skipped = false;
    linearAd.time = ad.time;

    return linearAd;
  }

  /**
   * Returns VAST object with nearest source height relative to the player height and filtred with forced format.
   *
   * @param {array} mediaFiles - array with VAST media files
   * @param {string} forcedFormat - forced format for filtering in media files
   * @returns {Object|null} VAST source or null
   */
  getNearestHeightSource(mediaFiles, forcedFormat) {
    const playerHeight = this.player.el_.offsetHeight;
    let nearestSource;

    mediaFiles.forEach((mediaFile) => {
      if (mediaFile.mimeType === forcedFormat) {
        const heightDifference = Math.abs(playerHeight - mediaFile.height);
        if (!nearestSource || heightDifference < nearestSource.heightDifference) {
          nearestSource = {
            heightDifference,
            source: mediaFile,
          };
        }
      }
    });

    if (!nearestSource) {
      log.warn(`[linear] not finded nearest height source (${forcedFormat})`, mediaFiles);
    }

    log('[linear] finded nearest height source', nearestSource);
    return nearestSource.source;
  }

  /**
   * Try to find available source in the break inventory
   *
   * @param {object} creative - Creative for this break
   * @returns {Object|null} Player new source or null
   */
  getPlayerSource(creative) {
    let selectedFile;
    const { forcedFormat } = this.settings.linear.qualityByHeight || {};

    if (forcedFormat) {
      selectedFile = this.getNearestHeightSource(creative.mediaFiles, forcedFormat);
    } else {
      const availableTypes = {};

      for (let i = 0; i < ((creative || {}).mediaFiles || []).length; i++) {
        availableTypes[creative.mediaFiles[i].mimeType] = creative.mediaFiles[i];
      }

      // Fixme BPo: When VAST contains more than one file this will select the last one
      const sourceOpportunity = availableTypes[this.player.currentType()];
      if (typeof sourceOpportunity !== 'undefined') {
        // Try to play current adaptive stream
        selectedFile = sourceOpportunity;
      } else if (typeof availableTypes[this.settings.linear.preferredFormat] !== 'undefined') {
        // Try to play prefered fallback type if exist - this will be probably mp4 in most cases
        selectedFile = availableTypes[this.settings.linear.preferredFormat];
      } else {
        // Fixme BPo: Try to select another type?
      }
    }

    if (selectedFile) {
      if (this.adProxy) {
        const formattedSource = sourceFormatter(selectedFile);
        return this.adProxy.updateSource(formattedSource);
      }
      return sourceFormatter(selectedFile);
    }

    return null;
  }

  /**
   * Returns informations about ad as object
   *
   * @param {Object} ad - Current ad
   * @returns {Object} Formatted informations about ad as object
   */
  getAdInfo(ad) {
    const adInfo = {
      id: ad.id || '',
      adTitle: ad.adTitle || '',
      advertiser: ad.advertiser || '',
      agency: '',
      brand: '',
    };
    if (ad.extensions) {
      adInfo.agency = ad.extensions.Agency || '';
      adInfo.brand = ad.extensions.Brand || '';
    }
    return adInfo;
  }

  /**
   * This will show the ad notification span element (automatically break when currentTime is greater than break inventory time).
   *
   * @param {String} adType - Ad type (available through availableAdType enum)
   * @param {?Int} adi - Used when break inventory contains more than 1 object
   */
  showAdNotification(adType, adi) {
    const { player } = this;
    // TODO BPo: Reset when seek

    return new Promise((resolve, reject) => {
      // Select break inventory (this function is defined in the integration plugin)
      const breakInventory = getInventory(player.adstate, adType, adi);
      if (breakInventory.ads.length < 1) {
        reject(
          new Error(
            `[linear] Linear ad break is skipped during upcoming notification. Inventory does not contain any ad. Ad type: ${adType}`,
          ),
        );
      }
      const adCount = breakInventory.ads.length;
      const { time } = breakInventory;
      const that = this;
      const priorNotificationTime = this.settings.linear.priorNotification.time;

      const notifyUpcomingAd = () => {
        const currentTime = player.currentTime();
        const timeToAd = time - currentTime;

        if (timeToAd > priorNotificationTime) {
          this.domOperations.hideAdMessageWrapperHTML();
        } else if (timeToAd <= 0) {
          player.off('timeupdate', notifyUpcomingAd);
          that.domOperations.removeAdMessageWrapperHTML();
          resolve();
        } else {
          this.domOperations.showAdMessageWrapperHTML();
          that.domOperations.updateUpcomingAdMessage(adCount, time - Math.floor(currentTime));
        }
      };

      const timeToAd = time - this.player.currentTime();
      if (timeToAd >= 0) {
        this.domOperations.createUpcomingAdHTML(adCount, Math.floor(timeToAd));
      } else {
        resolve();
      }
      player.on('timeupdate', notifyUpcomingAd);
    });
  }

  adended(adType, adi, breakInventory) {
    this.player.off('adloadedmetadata', this.wrapAdUIFunc);
    breakInventory.played++;
    // Reset midroll index to the defalt
    this.player.adstate.currentMidrollIndex = -1;

    // remove ad UI
    this.domOperations.removeLinearAdUI();

    if (breakInventory.played < breakInventory.ads.length) {
      switch (adType) {
        case avlAdType.preroll:
          this.player.trigger('readyforpreroll');
          break;
        case avlAdType.csaiMidrolls:
          this.player.trigger(CsaiEvents.CSAI_MIDROLLS_READY);
          break;
        case avlAdType.midrolls:
          log(`[linear] Check next midroll: ${adi}`);
          this.player.trigger({
            type: `nextmidroll${adi}`,
            cue: adi,
          });
          break;
        case avlAdType.postroll:
          this.player.trigger('postrollready');
          break;
      }
    } else {
      // Reset ad playing state to the default
      this.player.adstate.adPlaying = false;

      // See contrib ads documentation
      this.player.trigger('ads-ad-ended');
      this.player.ads.endLinearAdMode();
      // Remove current ad type when ad ended event fired
      this.player.adstate.currentAdtype = '';
      breakInventory.played = 0;

      switch (adType) {
        case avlAdType.preroll: {
          // Trigger manually event, that preroll ended right now
          this.player.trigger('prerollended');
          break;
        }
        case avlAdType.midrolls: {
          // Trigger manually event, that midroll ended right now
          this.player.trigger({
            type: 'midrollended',
            cue: adi,
          });
          break;
        }
        case avlAdType.postroll: {
          // Trigger manually event, that postroll ended right now
          this.player.pause();
          this.player.autoplay(false);
          this.player.trigger('postrollended');
          this.player.trigger(availableEvents.COMPLETE);
          break;
        }
        case avlAdType.csaiMidrolls: {
          this.player.trigger(CsaiEvents.CSAI_MIDROLLS_ENDED);
          break;
        }
      }
    }
  }

  /**
   * Play appropriate ad
   *
   * @param {String} adType - Ad type (available through availableAdType enum)
   * @param {?Int} adi - Used when break inventory contains more than 1 object
   */
  playAd(adType, adi) {
    // Shorthand
    const { player } = this;
    // Preserve this object for triggered events
    const that = this;

    // Reset any non linear content when ad start playing. Call this before currentAdtype change
    Cascade.resetShownCascade(player, this.adstate.currentAdtype);

    let ad;
    let breakInventory;

    if (adType === avlAdType.csaiMidrolls) {
      breakInventory = getInventory(player.adstate, avlAdType.csaiMidrolls);
      ad = breakInventory.ads[breakInventory.played] || {};
      player.adstate.currentAdtype = adType;
    } else {
      // Save info about current playing type
      player.adstate.currentAdtype = adType;

      // Select break inventory (this function is defined in the integration plugin)
      breakInventory = getInventory(player.adstate, adType, adi);

      // Select current ad
      ad = breakInventory.ads[breakInventory.played];
    }

    // Select crative from current break inventory
    const creative = ad.creatives?.find((creative) => creative.type === 'linear');

    if (hasSimid(creative)) {
      this.simidManager = new SimidManager(this.player);
      this.simidManager.initializeAd(ad, true);
      this.simidManager.playAd();
    }

    if (player.options_.plugins.adService.omidManager) {
      player.omidManager().initAd({ ad, type: 'linear' });
    }

    /**
     * This will append ad array index to the log message
     * @param  {String} logMessage A log message
     * @param  {Number=} adi       Linear ad array index
     * @return {String}            Message with appended index
     */
    const appendArrayIndex = function appendArrayIndex(logMessage, adi) {
      if (adi !== undefined) {
        logMessage = `[linear] ${logMessage}, array index: ${adi}`;
      }
      return logMessage;
    };
    // If there is no ad to play
    if (breakInventory.ads.length < 1) {
      log(
        appendArrayIndex(
          `[linear] Play linear ad triggered, but inventory does not contain any ad. Ad type: ${adType}`,
          adi,
        ),
      );
      return;
    }
    log(appendArrayIndex(`[linear] Play linear ad triggered. Ad type: ${adType}`, adi));

    // check if creative is VPAID
    if (isVPAID(creative)) {
      const vpaid = new VPAID(this.player, this.settings, this.adsPlayer.getVideoElement());
      vpaid.playAd(ad, creative, () => this.adended(adType, adi, breakInventory));
      return;
    }

    const playerSource = this.getPlayerSource(creative);

    if (!playerSource) {
      // Available source not found
      log(appendArrayIndex(`[linear] No available source found in the VAST response. Ad type: ${adType}`, adi));

      // FIXME: Try to resume playing
      // See cotrib ads documentation
      player.trigger('ads-ad-ended');
      player.ads.endLinearAdMode();
      player.adstate.adPlaying = false;
      // Return function before startLinearAdMode
      return;
    }

    // When ad loaded metadata do this process.
    const wrapAdUIFunc = () => {
      that.wrapAdUI(creative, breakInventory.ads.length, breakInventory.played + 1, adType, ad.id);
    };

    // don't show big play button over the creative when it's simid creative
    if (!hasSimid(creative)) {
      player.one('ads-ad-started', wrapAdUIFunc);
    }

    // Start linear ad through ads plugin
    if (!player.ads.isAdPlaying()) {
      player.pause();
      player.ads.startLinearAdMode();
    }

    // Set current linear ad id to the adstate player object
    player.adstate.linearAdId = ad.id;

    // Set adplaying status
    player.adstate.adPlaying = true;

    // register tracking for this linear ad
    this.linearTracker = new LinearTracker(player);
    this.linearTracker.track(ad, creative);

    log(`[linear] Source will be requested (type: ${playerSource.type}, src: ${playerSource.src})`);

    // See cotrib ads documentation
    player.one('adplaying', () => {
      log('[linear] playAd trigger ads-ad-started');
      player.currentTime(0);
      player.trigger('ads-ad-started');
    });

    player.currentTime(0);

    // FIXME BPo: This workaround is maybe ready to remove
    // Load the break inventory source
    try {
      player.src(playerSource);
    } catch (error) {
      // Try to load src even if there is error
      player.src(playerSource);
    }

    player.one('durationchange', function durationchange() {
      // FIXME BPo: This is never called probably. Remove this once is time to test functionality without this code
      this.play();
    });

    // When it's finnished do this
    player.one('adended', () => this.adended(adType, adi, breakInventory));
  }

  /**
   * Read or set a new value to the ad button enabled data attribute
   *
   * @param {Boolean} enabled Set new true/false value for the ad button.
   * @returns {Boolean} Current ad button enabled value
   */
  adButtonEnabled(enabled) {
    const adButton = this.domOperations.getAdButton();
    if (!adButton) {
      log.error(AS_AD_BUTTON_NOT_FOUND.code, AS_AD_BUTTON_NOT_FOUND.message);
    }

    if (typeof enabled !== 'undefined') {
      adButton.setAttribute('data-enabled', enabled);
    }
    return adButton.getAttribute('data-enabled') === 'true';
  }

  /**
   * Write ad UI overlay
   *
   * @param {mixed}   creative  Hash of values for the creative
   * @param {Number}  adCount   Length of the ads array in the current linear break
   * @param {Number}  adi       Current ad index
   */
  wrapAdUI(creative, adCount, adi, adType, adId) {
    // Remove old ad links if exists
    this.domOperations.removeLinearAdUI();
    // Shorthand
    const { player } = this;
    // Preserve this object
    const that = this;

    const adLinkClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      // Condition if ad has click url
      if (creative.videoClickThroughURLTemplate.url) {
        if (!that.player.paused()) {
          // Pause player when click on the ad link
          that.player.pause();
          // Call measurement pause event
          that.player.trigger('adpauseclicked');
        }
        // Trigger linear click throug
        that.player.trigger('linearclickthrough');
        // Open video click through link
        window.open(creative.videoClickThroughURLTemplate.url);
        return;
      }

      if (!that.player.paused()) {
        // Pause player when click on the ad link
        that.player.pause();
        // Call measurement pause event
        that.player.trigger('adpauseclicked');
      } else {
        // If ad has not click url and is paused, play on click
        that.player.play();
      }
    };

    const adButtonClick = (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (that.adButtonEnabled()) {
        // Remove old ad wrappers
        that.domOperations.removeLinearAdUI();

        // trigger ended to emulate ad ended but pass skipped info
        that.player.trigger({
          type: 'adended',
          skipped: true,
        });
      }
    };

    this.domOperations.createLinearAdUI(
      creative.videoClickThroughURLTemplate,
      adLinkClick,
      adButtonClick,
      adType,
      adId,
    );

    // Try to read skip delay or adstate delay fallback
    if (!creative.skipDelay && this.settings.linear.defaultSkipDelay) {
      creative.skipDelay = this.settings.linear.defaultSkipDelay;
    }

    // Try to parse skip delay
    const delay = parseInt(creative.skipDelay, 10);

    log(
      `[createSkipButtonUI] enable: ${showSkipDelay(this.settings)}, delay: ${delay}, adDuration: ${player.duration()}`,
    );
    if (
      this.player.adstate.currentAdtype !== 'csaiMidrolls' &&
      showSkipDelay(this.settings) &&
      delay &&
      delay < player.duration() &&
      !hasSimid(creative)
    ) {
      this.domOperations.createSkipButtonUI(adCount, adi, delay);
      // Show skip button only when ad is longer then skip duration or is not disabled
      const skipEvt = function skipEvt() {
        // HACK BPo: videojs-contrib-ads has bug when ad loading spinner keeps showing when playing
        player.removeClass('vjs-ad-loading');
        if (player.currentTime() >= delay) {
          player.off('adtimeupdate', skipEvt);
          that.domOperations.showSkipButton();
          that.adButtonEnabled(true);
        } else {
          that.domOperations.updateSkipCounter(delay);
        }
      };

      player.on('adtimeupdate', skipEvt);
      player.one('adended', () => {
        // Remove skip event once ad ended event occurs
        player.off('adtimeupdate', skipEvt);
      });
    }

    // Show promo plugin
    if (showPremiumPromo(this.settings)) {
      const premiumClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        // Pause player when click on the ad link
        that.player.pause();

        // Trigger promo link click
        that.player.trigger('promolinkclick');

        // Open video click through link
        if (this.settings.linear.premium.url) {
          window.open(this.settings.linear.premium.url);
        }
      };

      this.domOperations.createPremiumPromoUI(premiumClick);
    }
  }
}

export default AdsLinear;
